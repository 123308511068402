<template>
    <div>
        <home-page-brand />
		<div>
			<home-page-card-banner/>
		</div>
		<v-parallax
			dark
			height="700"
			src="https://cdn.pixabay.com/photo/2017/07/01/19/48/background-2462431_960_720.jpg">
			<!-- https://cdn.pixabay.com/photo/2017/07/01/19/48/background-2462431_960_720.jpg -->
			<!-- https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg -->
			<v-row align="center" justify="center">
				<v-col class="text-center" cols="12">
					<h2 class="text-h2 font-weight-thin mb-4">
						We work with you to provide the best solutions for you
					</h2>
				</v-col>
			</v-row>
		</v-parallax>
    </div>
</template>

<script>
import HomePageBrand from "./HomePageBrand.vue";
import HomePageCardBanner from './HomePageCardBanner.vue';
export default {
    components: { HomePageBrand,  HomePageCardBanner },
    name: "HelloWorld",

    data: () => ({
    }),
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/documentation/securityPrivacy',
    name: 'securityPrivacyDocumentation',
    component: () => import( '../views/documentation/SecurityPrivacy.vue')
  },
  {
    path: '/documentation/atlassian',
    name: 'atlassianDocumentatio',
    component: () => import( '../views/documentation/atlassianSoftware.vue')
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import( '../views/recruitment/recruitment.vue')
  }
 /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/AboutView.vue') // webpackChunkName: "about"
  }*/
]

const router = new VueRouter({
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faApple,faAtlassian } from '@fortawesome/free-brands-svg-icons'
import { faCode,faChartPie, faPeopleGroup,faBook,faHeadset,faClock,faMapMarker,faSyringe,faUserPlus,faListCheck } from '@fortawesome/free-solid-svg-icons'
//import {  } from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faApple,faCode,faAtlassian,faChartPie, faPeopleGroup, faBook,faHeadset,faClock,faMapMarker,faSyringe,faUserPlus,faListCheck)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

require("./assets/styles/main.css")

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

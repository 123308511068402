<template>
	<v-card outlined :elevation="10" class="text-center align-center">
		<font-awesome-icon :icon="card.icon" size="4x" class="mt-6 " :color="card.iconColour"/>
		<v-card-title primary-title class="text-h5 justify-center font-weight-bold">{{card.title}}</v-card-title>
		<v-card-text class="body-1">
			{{card.text}}
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name:"serviceCard",
	props:["card"],
    data: () => ({
    }),
    computed: {
       
    },
};
</script>

<template>
	<v-app>
		<v-app-bar app color="primary" dark>
			<router-link
				to="/"
				class="d-flex align-center"
				:style="{
					color: $vuetify.theme.themes[theme].headingLogo,
					'text-decoration': 'none',
				}"
			>
			
				<h1><span class="titleHeadingLeft">Binary</span> <span class="titleHeadingRight">Hub</span></h1>
			</router-link>
			<!-- <div class="d-flex align-center">
				<h1>Binary Hub</h1>-->
			<!--<v-img
					alt="Vuetify Logo"
					class="shrink mr-2"
					contain
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
					transition="scale-transition"
					width="40"
				/>

				<v-img
					alt="Vuetify Name"
					class="shrink mt-1 hidden-sm-and-down"
					contain
					min-width="100"
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
					width="100"
				/> -->
			<!--</div>-->
			<v-spacer></v-spacer>
			<header-link :links="links" />
			<v-app-bar-nav-icon @click="drawer = true" class="ml-10"></v-app-bar-nav-icon>
		</v-app-bar>

		<v-main
			:style="{ background: $vuetify.theme.themes[theme].background }"
		>
			<router-view />
		</v-main>
		<footer-view />
		<v-navigation-drawer
			v-model="drawer"
			fixed
			right
			temporary
			class="primary lighten-1 mx-auto"
		>
			<v-list nav dense>
				<v-list-item-group
					v-model="group"
					active-class="text--accent-4"
				>
					<!--https://apps.apple.com/us/developer/david-martin/id959882185-->
					<v-list-item
						class="pt-12"
						@click="
							mobileApps = !mobileApps;
							$vuetify.theme.themes.dark = false;
						"
					>
						<v-list-item-icon>
							<font-awesome-icon
								icon="fa-brands fa-apple"
								size="xl"
								class="white--text"
							/>
						</v-list-item-icon>
						<v-list-item-title class="white--text"
							>Mobile Applications</v-list-item-title
						>
					</v-list-item>
					<v-list v-if="mobileApps" class="pl-16 pb-5">
						<v-list-item
							href="https://apps.apple.com/au/app/sight-words-australia-home-version-qld/id964434469"
							target="_blank"
						>
							<v-list-item-title class="white--text"
								>Sight Words Australia</v-list-item-title
							>
						</v-list-item>
						<v-list-item
							href="https://apps.apple.com/au/app/running-record-calculator/id963571823"
							target="_blank"
						>
							<v-list-item-title class="white--text"
								>Running Records</v-list-item-title
							>
						</v-list-item>
						<v-list-item
							href="https://apps.apple.com/au/app/teach-pad/id1551120839"
							target="_blank"
						>
							<v-list-item-title class="white--text"
								>Teach Pad</v-list-item-title
							>
						</v-list-item>
					</v-list>
					<v-list-item @click="documentation = !documentation">
						<v-list-item-icon>
							<font-awesome-icon
								icon="fa-solid fa-book"
								size="xl"
								class="white--text"
							/>
						</v-list-item-icon>
						<v-list-item-title class="white--text"
							>Documentation</v-list-item-title
						>
					</v-list-item>
					<v-list v-if="documentation" class="pl-16 pb-5">
						<!--	<v-list-item >
							<v-list-item-title class="white--text">Sight Words Australia</v-list-item-title>
						</v-list-item>
						<v-list-item >
							<v-list-item-title class="white--text">Running Records</v-list-item-title>
						</v-list-item>
						<v-list-item >
							<v-list-item-title class="white--text">Teach Pad</v-list-item-title>
						</v-list-item>-->
						<v-list-item>
							<v-list-item-title class="white--text">
								<router-link
									class="noUnderline"
									to="/documentation/atlassian"
									:style="{
										color: $vuetify.theme.themes[theme]
											.link,
									}"
								>
									Atlassian Application
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title class="white--text">
								<router-link
									class="noUnderline"
									to="/documentation/securityPrivacy"
									:style="{
										color: $vuetify.theme.themes[theme]
											.link,
									}"
								>
									Security &amp; Privacy
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
					<v-list-item>
						<v-list-item-icon>
							<font-awesome-icon size="xl" class="white--text" icon="fas fa-user-plus" />
						</v-list-item-icon>
						<v-list-item-title class="white--text">
								<router-link
									class="noUnderline"
									to="/Recruitment"
									:style="{
										color: $vuetify.theme.themes[theme]
											.link,
									}"
								>
									Recruitment
								</router-link>
							</v-list-item-title>
					</v-list-item>
					<!--<v-list-item>
						<v-list-item-icon>
							<font-awesome-icon icon="fa-solid fa-people-group" size="xl" class="white--text"/>
						</v-list-item-icon>
						<v-list-item-title class="white--text">Recruitment</v-list-item-title>
					</v-list-item>-->
				</v-list-item-group>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn block href="https://binaryhub.atlassian.net/servicedesk" target="_blank">
						<font-awesome-icon
							icon="fa-solid fa-headset"
							size="l"
							class="black--text mr-2"
						/>
						Support
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>
	</v-app>
</template>

<script>
import footerView from "./components/layout/footer.vue";
import HeaderLink from "./components/header/headerLink.vue";
import '../src/assets/styles/sass/default.scss'
export default {
	components: { footerView, HeaderLink },
	name: "App",

	data: () => ({
		drawer: null,
		mobileApps: false,
		documentation: false,
		group: null,
		links: [
			{
				url: "/recruitment",
				text: "Recruitment",
			},
		],
	}),
	computed: {
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},
	},
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#424242',
				/*secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',*/
				link:"#FFFFFF",
				background:'#FFFFFF',
				documentBackground:"#FFFFFF",
				documentText:"#000000",
				mainHeading:'#FFFFFF',
				headingLogo:'#FFFFFF'
			},
			light_old: {
				primary: '#1A237E',
				/*secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',*/
				link:"#E8EAF6",
				background:'#3949AB',
				documentBackground:"#FFFFFF",
				documentText:"#000000"
			},
		}
	}
});
export default vuetify;

<template>
	<v-parallax
		src="../assets/img/groupS.jpg"> 
		<!-- https://cdn.pixabay.com/photo/2020/05/31/09/45/city-5242045_1280.jpg -->
		<!-- https://cdn.pixabay.com/photo/2015/12/03/01/05/architect-1073608_1280.jpg -->
		<!-- https://cdn.pixabay.com/photo/2019/06/26/19/33/metal-4301083_1280.jpg -->
		<!-- https://cdn.pixabay.com/photo/2022/02/18/06/17/futuristic-7020072_1280.jpg -->
		<v-row align="center" justify="center">
			<v-col class="text-center" cols="12">
				<h1 class="text-h1 font-weight-thin mb-4" :style="{
					color: $vuetify.theme.themes[theme].mainHeading,
				}">
					
						<span class="titleHeadingLeft">Binary</span>
						<br v-if="showBreak"/>
						<span class="titleHeadingRight">Hub</span>
				</h1>
				<h4 class="text-h5 subheading">Architects of Change</h4>
			</v-col>
		</v-row>
	</v-parallax>
</template>
<script>
//import group from '../assets/img/groupXXL.jpg'
export default {
    name: "Home-Page-Brand",
    setup() {},
	computed: {
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},
		showBreak() {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return true;
			case 'sm': return true
			case 'md': return false
			case 'lg': return false
			case 'xl': return false
			default: return false;
			}
		}
	},
};
</script>

<template>
	<span >
		<span class="mr-2 hidden-md-and-down" v-for="link in links" :key="link" >
			<router-link
				:to="link.url"
				:style="{ color: $vuetify.theme.themes[theme].link, 'text-decoration':'none'  }" class="text-body1 titleLinkFont">
					{{link.text}}
			</router-link>
		</span>
	</span>
</template>
<script>
export default {
	name:"headerLink",
	props:["links"],
    data: () => ({
    }),
     computed: {
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },
}
</script>
<template>
    <div class="d-flex flex-wrap justify-center pb-10 mb-16 pt-10" no-gutters :style="{background:'clear'}">
        <!--<v-flex tile v-for="card in cards" :key="card" class="d-flex flex-wrap flex-grow-0 flex-shrink-0 pa-4" >-->
        <v-card
            v-for="card in cards"
            :key="card"
            class="d-flex pl-4 pr-4 justify-center flex-grow-0 flex-shrink-0 background"
            :elevation="0"
            :style="{maxWidth:'22em'}"
        >
            <service-card :card="card" />
        </v-card>
        <!--</v-flex>-->
    </div>
</template>
<script>
import ServiceCard from "./basicElement/serviceCard.vue";

export default {
    components: { ServiceCard },
    name: "homePageCardBanner",
    data: () => ({
        cards: [
            {
                icon: "fa-solid fa-code",
                title: "Software Development",
                text: "Custom software solutions that scale with you across all your systems",
				iconColour:"gray"
            },
            {
                icon: "fa-brands fa-atlassian",
                title: "Atlassian Software",
                text: "Engage with your clients in a customised user centric environment",
				iconColour:"blue"
            },
            {
                icon: "fa-solid fa-chart-pie",
                title: "Data",
                text: "Unlock the potential of your data and make it work for you",
				iconColour:"gray"
            },
        ],
    }),
    computed: {},
};
</script>

<template>
  <hello-world />
</template>

<script>
import HelloWorld from "../components/HelloWorld";

export default {
    name: "Home-view",

    components: {
        HelloWorld
    },
};
</script>

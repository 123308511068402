<template>
	<v-footer :padless="true" :fixed="true">
		<v-card flat tile width="100%" class="text-center" color="primary">
			<!--<v-card-text>
				<v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon href="/">
					<v-icon size="24px">
						{{ icon }}
					</v-icon>
				</v-btn>
				<v-btn class="mx-4 white--text" icon href="https://apps.apple.com/us/developer/david-martin/id959882185">
					<font-awesome-icon icon="fa-brands fa-apple" size="xl"/>
				</v-btn>
			</v-card-text>
			<v-divider></v-divider>-->
			<v-card-text class="white--text">
				{{ new Date().getFullYear() }} — <strong>Binary Hub</strong>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<script>
export default {
	name:"footerView",
    data: () => ({
        icons: ["mdi-home"],
        items: ["default", "absolute", "fixed"],
        variant: "default",
    }),
    computed: {
        localAttrs() {
            const attrs = {};

            if (this.variant === "default") {
                attrs.absolute = false;
                attrs.fixed = false;
            } else {
                attrs[this.variant] = true;
            }
            return {"absolute":true};
        },
    },
};
</script>
